<template>
	<div
		class="statusCard white d-flex status-card justify-space-between px-4 py-2"
		@click="ChangeStatus"
	>
		<div style="display: flex; flex-direction: column; justify-content: space-between">
			<div>
				<div :class="textColor" class="heading">{{ heading }}</div>
				<div style="margin-bottom: -6px" :class="textColor" v-if="subHeadingText">
					{{ subHeadingText }}
				</div>
			</div>
			<div class="mt-1">
				<inline-svg
					class="icons"
					v-if="type == 'vendor'"
					fill="#B0BEC5"
					:src="$assetURL('media/custom-svg/supplier.svg')"
				/>
				<inline-svg
					class="icons"
					v-else-if="type == 'inventory'"
					fill="#B0BEC5"
					:src="$assetURL('media/custom-svg/inventory.svg')"
				/>
				<inline-svg
					class="icons"
					v-else-if="type == 'services'"
					fill="#B0BEC5"
					:src="$assetURL('media/custom-svg/service.svg')"
				/>
				<inline-svg
					class="icons"
					v-else-if="type == 'purchase-order'"
					fill="#B0BEC5"
					:src="$assetURL('media/custom-svg/purchase-order.svg')"
				/>
				<inline-svg
					class="icons"
					v-else-if="type == 'quotation'"
					fill="#B0BEC5"
					:src="$assetURL('media/custom-svg/quotation.svg')"
				/>
				<inline-svg
					class="icons"
					v-else-if="type == 'project'"
					fill="#B0BEC5"
					:src="$assetURL('media/custom-svg/project.svg')"
				/>
				<inline-svg
					class="icons"
					v-else-if="type == 'expense'"
					fill="#B0BEC5"
					:src="$assetURL('media/custom-svg/claim-expense.svg')"
				/>
				<inline-svg
					class="icons"
					v-else-if="type == 'report'"
					fill="#B0BEC5"
					:src="$assetURL('media/custom-svg/report.svg')"
				/>
				<v-icon class="icons" v-else x-large color="blue-grey lighten-3">{{ icons }}</v-icon>
			</div>
		</div>
		<div
			class="d-flex d-flex-column justify-space-between align-items-end"
			style="display: flex; flex-direction: column"
		>
			<div :class="textColor" class="heading">{{ statusCount }}</div>
		</div>
	</div>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
export default {
	name: "status-card",
	mixins: [ListingMixin],
	data() {
		return {
			endpoint: null,
		};
	},
	props: {
		statusColor: {
			type: String,
			default: "",
		},
		statusCount: {
			type: [Number, String],
			default: 0,
		},
		value: {
			value: String,
		},
		heading: {
			type: String,
			default: "",
		},
		shades: {
			type: String,
			default: "",
		},
		icons: {
			type: String,
			default: "",
		},
		type: {
			type: String,
		},
		textColor: {
			type: String,
		},
		defaultendpoint: {
			type: String,
		},
		subHeding: {
			type: Boolean,
			default: false,
		},
		subHeadingText: {
			type: String,
			default: null,
		},
	},
	methods: {
		ChangeStatus() {
			// this.endpoint = this.defaultendpoint;
			// // this.pageLoading=true
			// this.filterRows();
			this.$emit("changeStatus", this.value);
		},
	},
};
</script>
